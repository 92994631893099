@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Astonpoliz';
  src: url('./fonts/Astonpoliz.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-LightCaps.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maytra';
  src: url('./fonts/Maytra.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.sheen {
  position: relative;
  overflow: hidden;
}

.sheen::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 200%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 50%);
  transform: skewX(-20deg);
  animation: none;
}

.sheen.animate::before {
  animation: sheen 1.5s ease-in-out;
}

/* Hidden by default */
.congratulations-message {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Fade-in effect */
}

/* When fade-in class is applied, make it fully visible */
.fade-in {
  opacity: 0;
}

.congratulations-message {
  /* background-color: #d1fae5; */
  /* color: #065f46; */
  border-radius: 5px;
  /* margin-top: 20px; */
  opacity: 0.8;
}
